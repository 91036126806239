import styled from 'styled-components';

export const Profile = styled.img`
  width: 250px;
  border-radius: 2%;
  max-height: 250px;
  object-fit: contain;
`;

export const Doctor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 20px;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Name = styled.h2`
  margin: 20px;
`;
export const Bio = styled.p`
  font-weight: 600;
  line-height: 1.4;
`;
