import React, { useState } from 'react';
import { FaBars, FaTimes, FaHandshake, FaMapMarkedAlt } from 'react-icons/fa';
import { IoIosHome, IoIosDocument } from 'react-icons/io';
import { IconContext } from 'react-icons/lib';
import sdiLogo from '../../resources/sdi-logo.svg';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavTitleSection,
  NavSubitle,
} from './Navbar.elements';

const Navbar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>
      <IconContext.Provider value={{ color: '#39729b' }}>
        <Nav>
          <NavbarContainer>
            <NavLogo to="/">
              <img alt="SDI Logo" src={sdiLogo} style={{ width: '100px', height: '100px' }} />
              <NavTitleSection>
                <h3>Sleep Disorders Institute</h3>
                <NavSubitle>Quality Sleep Studies, Equipment and Supplies</NavSubitle>
              </NavTitleSection>
              <img
                alt="ACHC Accredited"
                src="https://www.achc.org/wp-content/uploads/2021/08/ACHC-Pillar.svg"
                style={{ marginLeft: '10px', width: '50px', height: '50px' }}
              />
            </NavLogo>

            <MobileIcon onClick={handleClick}>{click ? <FaTimes /> : <FaBars />}</MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLinks to="/">
                  <IoIosHome size={42} />
                  <span>Home</span>
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/meet_us">
                  <FaHandshake size={42} />
                  <span>Physicians</span>
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/locations">
                  <FaMapMarkedAlt size={42} />
                  <span>Locations</span>
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/forms">
                  <IoIosDocument size={42} />
                  <span>Forms/FAQ</span>
                </NavLinks>
              </NavItem>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
