import styled from 'styled-components';

export const Sleep = styled.img`
  width: 100%;
  height: 50vh;
  object-fit: cover;
  border-radius: 1%;
`;

export const Content = styled.div`
  padding: 1% 0;
  display: flex;
  justify-content: space-around;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

export const ColumnContentMain = styled.div`
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  flex: 1 1 45%;
`;

export const ColumnContent = styled.div`
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
`;

export const Title = styled.h1`
  text-align: left;
  @media screen and (max-width: 960px) {
    padding-top: 20px;
  }
`;
export const Body = styled.p`
  padding-top: 10px;
  font-weight: 600;
  line-height: 1.4;
`;

export const Services = styled.ul`
  padding: 2% 0 2% 3em;
  text-align: left;
  display: inline-block;
`;

export const Hours = styled.div`
  padding: 2% 1em;
  text-align: center;

  @media screen and (max-width: 960px) {
    text-align: left;
  }
`;

export const CenteredText = styled.p`
  text-align: center;

  @media screen and (max-width: 960px) {
    text-align: left;
  }
`;
