import styled from 'styled-components';
import { GrDocumentPdf } from 'react-icons/gr';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';

export const DocumentItem = ({ docName, doc }) => {
  return (
    <StyledListItem>
      <GrDocumentPdf size={30} />
      <a
        target="_blank"
        rel="noreferrer"
        href={'https://sleep-disorders-institute.s3.amazonaws.com/' + doc}
        style={{ paddingLeft: '30px' }}
      >
        {docName}
      </a>
    </StyledListItem>
  );
};

export const MuiAccordion = ({ data, scrollTo }) => {
  const [expanded, setExpanded] = useState(scrollTo);

  useEffect(() => {
    if (scrollTo) setExpanded(scrollTo);
  }, scrollTo);
  const handleChange = (question) => {
    if (expanded === question) setExpanded(null);
    else setExpanded(question);
  };
  return data.map((combo) => (
    <StyledAccordion expanded={expanded === combo.question} onChange={() => handleChange(combo.question)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <StyledTypography>{combo.question}</StyledTypography>
      </AccordionSummary>
      <AccordionDetails>
        {combo.subQuestions == null || combo.subQuestions.length === 0 ? (
          <StyledTypography dangerouslySetInnerHTML={{ __html: `${combo.answer}` }} />
        ) : (
          <MuiAccordion data={combo.subQuestions} scrollTo={scrollTo} />
        )}
      </AccordionDetails>
    </StyledAccordion>
  ));
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  border-top: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  padding: 1%;
  padding-right: 0;
  margin: 1% 0;
`;

export const DocumentList = styled.ul`
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
  text-indent: -1em;
`;

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const SubHeading = styled.h3`
  padding: 1%;
  padding-right: 0;
`;

const StyledTypography = styled(Typography)`
  font-weight: 600 !important;
`;

const StyledAccordion = styled(Accordion)`
  background-color: rgba(255, 255, 255, 0.4) !important;
`;
