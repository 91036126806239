import React, { useEffect } from 'react';
import InfoSection from '../InfoSections';
import SleepingJpg from '../../../resources/HomeSleeping.jpg';
import {
  Sleep,
  Content,
  ColumnContentMain,
  Body,
  Title,
  Hours,
  ColumnContent,
  Services,
  CenteredText,
} from './Home.elements';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <InfoSection>
      <Sleep src={SleepingJpg} alt="Sleep Apnea" />
      <Content>
        <ColumnContentMain>
          <Title>Welcome to Sleep Disorders Institute</Title>
          <Body>
            Sleep Disorders Institute has been diagnosing and treating patients with sleep disorders since 2004. The
            staff at Sleep Disorders Institute are experts in sleep medicine. We are locally owned and can give you that
            personal experience with complete professionalism. Our mission is to guide you through this sometimes
            confusing process of understanding your sleep disorders and helping you treat it.
            <br />
            <br />
            Our physicians specialize in diagnosing and treating sleep disorders. Our clinical staff includes board
            certified sleep physicians and professionally trained, registered, sleep technologists with many years of
            experience in administering diagnostic testing and treatment. We are knowledgeable, friendly and patient. We
            offer Home Sleep Testing and Attended Sleep Studies.
            <br />
            <br />
            Our sleep centers provide a restful night’s sleep similar to a home environment. Our sleep rooms are
            private, with comfortable queen size beds, cable TV, and state of the art diagnostic equipment. We have
            sleep centers throughout the Kansas City area. We are contracted with all major medical insurances including
            Blue Cross and Blue Shield, Tricare and Medicare.
          </Body>
        </ColumnContentMain>
        <ColumnContent>
          <Title>Treatments and Services</Title>
          <Body>
            <h2>Services</h2>
            <Services>
              <li>
                <a href="/forms?scrollTo=Home">Home Sleep Test</a> - Appointments & Devices available weekly
              </li>
              <li>
                <a href="/forms?scrollTo=Lab">In Lab Sleep Study</a>
              </li>
              <li>Multiple Sleep Latency Test</li>
              <li>Sleep Consultation</li>
              <li>CPAP Equipment and Supplies</li>
              <li>
                <a href="/dot">DOT</a> - Testing and Downloads available
              </li>
              <li>Compliance Reports</li>
            </Services>
            <h2>
              <a href="/sleep_disorders">Sleep Disorders</a>
            </h2>
            <Services>
              <li>Sleep Apnea</li>
              <li>Narcolepsy</li>
              <li>Shift Workers Sleep Disorders</li>
              <li>Parasomnia</li>
              <li>Sleepwalking</li>
              <li>Other</li>
            </Services>
          </Body>
        </ColumnContent>
        <ColumnContent>
          <Title>Contact Us</Title>
          <Body>
            <CenteredText>
              Phone: <a href="tel:9137543275">913-754-3275</a>
            </CenteredText>
            <CenteredText>Fax: 913-754-3276</CenteredText>
            <CenteredText style={{ padding: '15px 0' }}>
              Email: <br />
              <a href="mailto:sleepdisordersinstitute@gmail.com">sleepdisordersinstitute@gmail.com</a>
            </CenteredText>
            <Hours>
              <p>Office Hours:</p>
              <p>Monday/Wednesday</p>
              <p>9:00am-6:00pm</p>
              <p>Tuesday/Thursday/Friday</p>
              <p>9:00am-5:00pm</p>
            </Hours>
          </Body>
        </ColumnContent>
      </Content>
    </InfoSection>
  );
};

export default Home;
