import SleepLab from '../../../resources/SleepLab.jpg';
export const FormData = [
  {
    question: 'Home Sleep Testing',
    answer: `Home sleep testing is carried out in the familiar surroundings of a person's own home, advantages include; reduced cost, simplicity, and comfort. In fact some insurance companies now mandate home testing unless there are specific reasons a lab study is required. Home sleep testing may be the best choice for patients that have symptoms with a high likelihood of OSA. Home sleep testing is not the best choice if you suspect a sleep disorder other than obstructive sleep apnea or if you have a pulmonary disease, neuromuscular disease, or congestive heart failure. If it is determined that you are a good candidate for home sleep testing you should avoid naps and caffeine on the day of your study. You should stick to your normal routine in all other areas.<br/><br/> <iframe style="display:block; margin: 0 auto" src="https://www.youtube.com/embed/awa4z2fFn7A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    subQuestions: [],
  },
  {
    question: 'A Night in the Sleep Facility',
    answer: '',
    subQuestions: [
      {
        question: 'You will have the safest sleep study possible',
        answer:
          'The same sleep you experience at home will also occur in the sleep facility except that it will be observed and measured. Nothing is done in the lab to change sleep-related events nor is anything inserted into the body. In fact, an elaborate, well-planned pre-sleep regimen is undertaken. This regimen includes preparing and applying external electrodes and answering any questions that you may have concerning sleep and the sleep lab. Some patients may feel uncomfortable when they are wired with electrodes or are monitored by microphones and visual observation but they usually sleep adequately. The sleep lab environment is safe and conducive to sleep. It is dark, quiet and pleasing with controlled, comfortable temperatures.',
      },
      {
        question: 'How do I prepare for the sleep lab?',
        answer:
          'Most importantly, maintain your normal daily schedule and avoid starting a new diet or exercise program until after the sleep study. If you are taking any sleep medications, your physician may ask you to discontinue taking it for at least one week before coming for your study. Generally, patients do not need to discontinue their medications. However, ask your physician and be sure to tell the sleep center personnel about any medications that you are taking.<br/>Your appointment will be for 8:30 or 9:00 PM. Please eat your evening meal at least 1 to 1 ½ hours before arriving at the facility. Avoid foods that contain caffeine including coffee, tea, cola, and chocolate during the day of the scheduled study. Also, avoid alcoholic beverages and do not take naps during the day. Bring your regular, comfortable night clothes, a robe, slippers, and even your own pillow if you think it will help you sleep better. Finally, please do not apply any oils or conditioners in your hair since these may interfere with the application of electrodes. Also, please let the sleep lab know before your appointment if you have any type of hair weaves, wigs, caps, etc. that would prevent the technician from applying electrodes directly on your scalp. Your sleep study will need to be scheduled for a time when these items are not preventing access to your scalp.',
      },
      {
        question: 'What does a room in the sleep lab look like?',
        answer: `<img src=${SleepLab} style='width: 100%'/>`,
      },
      {
        question: 'How is the polysomnogram performed?',
        answer:
          'A certified technician (polysomnographer) will conduct the evaluation. After you are dressed for sleep, the technician will use a paste to apply approximately 20 small, lightweight electrodes to your scalp and skin. Once you are in bed, the technician will apply an oxygen sensor to a finger and 2 straps to your chest/stomach. These devices will help us to measure your breathing and determine why your sleep is being disturbed. The attachments are plugged into a box which is easy to disconnect if you need to get out of bed during the night. The technician will be in an adjacent monitoring room. If you need anything, you will be observed by both camera and intercom.',
      },
      {
        question: 'What is a Multiple Sleep Latency Test (MSLT)',
        answer:
          'The multiple sleep latency test(MSLT) tests for excessive daytime sleepiness by measuring how quickly you fall asleep in a quiet environment during the day. Also known as a daytime nap study, the MSLT is the standard tool used to diagnose narcolepsy and idiopathic hypersomnia. The MSLT is a full-day test that consists of 5 scheduled naps separated by two-hour breaks. During each nap trial, you will lie quietly in bed and try to go to sleep. Once the lights go off, the test will measure how long it takes for you to fall asleep. You will be awakened after sleeping 15 minutes. If you do not fall asleep within 20 minutes, the nap trial will end.<br/><br/>Each nap will be taken in a dark and quiet sleep environment that is intended for your comfort and to isolate any external factors that may affect your ability to fall asleep. A series of sensors will measure whether you are asleep. The sensors also determine your sleep stage. The MSLT will be preceded by an overnight sleep study in the facility. This is to ensure that the previous night’s sleep was adequate and not disrupted by another sleeping disorder or other external factors. The test will be looking for extensive daytime sleepiness. Excessive daytime sleepiness occurs when you are sleepy when you should be awake and alert. Our board-certified sleep medicine physicians will recommend an MSLT if they suspect you have excessive daytime sleepiness related to narcolepsy or hypersomnia.',
      },
      {
        question: 'How long will the study last?',
        answer:
          'An overnight sleep study usually ends between 5:30 - 6:00 AM the following morning. If a “nap study” during the day (Multiple Sleep Latency Test/MSLT) is requested, it follows the overnight study and ends around 4:30 - 5:00 PM the next day. ',
      },
      {
        question: 'When are the results known?',
        answer:
          'A tremendous volume of sleep information is collected on paper and in the computer. The scored study, sleep history, physical examination, the technicians observation notes and any other testing that you may have had performed will be forwarded to  one of our interpreting physicians who are sleep specialists. Careful interpretation of all results is then undertaken. This leads to a diagnosis of the sleep problem as well as recommendations for treatment. The final report is completed within 10 to 14 business days. We will call you to go over the results of the sleep study or we will schedule a consultation with the doctor to review. If you have any questions, please do not hesitate to ask. All sleep facility personnel are ready to help you to have a comfortable stay during your sleep study.',
      },
    ],
  },
];
