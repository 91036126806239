import React from 'react';
import styled from 'styled-components';

export const Footer = () => {
  return <StyledFooter>&#169;{new Date().getFullYear()} Sleep Disorders Institute | All Right Reserved</StyledFooter>;
};

const StyledFooter = styled.div`
  width: 100%;
  justify-content: center;
  padding: 15px auto;
  position: sticky;
  bottom: 0;
`;
