import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import GlobalStyle from './globalStyles';
import { Navbar } from './components';
import Home from './components/InfoSections/Home/Home';
import clouds from './resources/clouds.jpg';
import { Footer } from './components/Footer/Footer';
import MeetUs from './components/InfoSections/MeetUs/MeetUs';
import Locations from './components/InfoSections/Locations/Location';
import Forms from './components/InfoSections/FAQ&Forms/Forms';
import Truckers from './components/InfoSections/Truckers/Truckers';
import SleepDisorders from './components/InfoSections/SleepDisorders/SleepDisorders';

function App() {
  return (
    <div style={{ background: `url(${clouds})` }}>
      <Router>
        <GlobalStyle></GlobalStyle>
        <Navbar />
        <Content>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/meet_us" exact element={<MeetUs />} />
            <Route path="/locations" exact element={<Locations />} />
            <Route path="/forms" exact element={<Forms />} />
            <Route path="/dot" exact element={<Truckers />} />
            <Route path="/sleep_disorders" exact element={<SleepDisorders />} />
          </Routes>
        </Content>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

const Content = styled.div`
  min-height: 100vh;
`;
