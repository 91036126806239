import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  min-height: 80vh;

  @media screen and (max-width: 1300px) {
    display: block;
    transition: all 0.5s ease;
    width: 100%;
    padding: 0 20px;
  }
`;

export const Locations = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 1%;
  min-width: 240px;
  width: 25%;
  justify-content: space-around;
  @media screen and (max-width: 1300px) {
    min-width: unset;
    width: 100%;
  }
`;

export const LocationSection = styled.div`
  padding: 10px;
  text-align: center;

  &:hover {
    background-color: lightgray;
    cursor: pointer;
  }

  @media screen and (max-width: 1300px) {
    transition: all 0.5s ease;
    width: 100%;
  }
`;

export const TitleSection = styled.div`
  color: #39729b;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 3em;
`;

export const LocationTitle = styled.h1`
  font-size: 2em;
  ${({ active }) =>
    active &&
    `
    text-decoration: underline;
  }
  `}
`;

export const LocationSubTitle = styled.h1`
  font-size: 1.6em;
  ${({ active }) =>
    active &&
    `
    text-decoration: underline;
  }
  `}
`;

export const StyledMap = styled.iframe`
  width: 95%;
  border: 0;
  @media screen and (max-width: 1300px) {
    height: 500px;
  }
`;
