import React, { useEffect, useState } from 'react';
import InfoSection from '../InfoSections';
import { DocumentItem, MuiAccordion, Container, Title, DocumentList, SubHeading } from './Forms.elements';
import { FormData } from './Forms.data';
import { useSearchParams } from 'react-router-dom';
import CoolPicJpg from '../../../resources/about-1.jpg';
import { Sleep } from '../Home/Home.elements';

const Forms = () => {
  const faq = React.createRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [scrollTo, setScrollTo] = useState(null);
  useEffect(() => {
    let scroll = searchParams.get('scrollTo');
    if (!scroll) {
      window.scrollTo(0, 0);
    } else {
      faq.current?.scrollIntoView({ behavior: 'smooth' });
      if (scroll === 'Home') {
        setScrollTo('Home Sleep Testing');
      } else if (scroll === 'Lab') {
        setScrollTo('A Night in the Sleep Facility');
      } else {
        setScrollTo(null);
      }
    }
  }, [searchParams]);
  return (
    <InfoSection>
      <Sleep src={CoolPicJpg} alt="Sleep Apnea" />
      <Container>
        <Title>Forms</Title>
        <DocumentList>
          <SubHeading>Patient Forms</SubHeading>
          <DocumentItem docName="Sleep History Questionnaire" doc={'SleepHistory.pdf'}></DocumentItem>
          <DocumentItem docName="Self Assessment" doc={'SleepAssessment.pdf'}></DocumentItem>
          <DocumentItem docName="CPAP Supplies Request" doc={'CPAP_Supplies.pdf'}></DocumentItem>
          <SubHeading>Physician Forms</SubHeading>
          <DocumentItem docName="Referral Form" doc={'PhysiciansForm.pdf'}></DocumentItem>
        </DocumentList>
      </Container>
      <Container>
        <Title ref={faq}>FAQ</Title>
        <MuiAccordion data={FormData} scrollTo={scrollTo} />
      </Container>
    </InfoSection>
  );
};
export default Forms;
