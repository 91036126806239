import React from 'react';
import styled from 'styled-components';

const InfoSection = ({ backgroundImg, children }) => {
  return (
    <MainContainer>
      <InfoBody>{children}</InfoBody>
    </MainContainer>
  );
};

export default InfoSection;

const MainContainer = styled.div`
  width: 65%;
  margin: 3% auto;

  @media screen and (max-width: 960px) {
    transition: all 0.5s ease;
    width: 100%;
    padding: 20px 0;
  }
`;

const InfoBody = styled.div`
  background-color: rgba(255, 255, 255, 0.6);
  padding: 1%;
  border-radius: 1%;
  display: flex;
  flex-direction: column;
`;
