import React, { useState, useEffect } from 'react';
import InfoSection from '../InfoSections';
import {
  LocationSection,
  Locations,
  Layout,
  Title,
  LocationTitle,
  StyledMap,
  TitleSection,
  LocationSubTitle,
} from './Location.elements';
import { LocationData } from './Location.data';

const Location = () => {
  const [location, setLocation] = useState(LocationData[0].googleMap);
  const [active, setActive] = useState(LocationData[0].googleMap);

  const clicked = (newLocation) => {
    setLocation(newLocation);
    setActive(newLocation);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const LocationInfo = () => {
    return LocationData.map((location) => (
      <LocationSection onClick={() => clicked(location.googleMap)}>
        {location.title === 'Overland Park' ? (
          <LocationTitle active={active === location.googleMap}>{location.title}</LocationTitle>
        ) : (
          <LocationSubTitle active={active === location.googleMap}>{location.title}</LocationSubTitle>
        )}

        <h3>{location.subtitle}</h3>
        {location.firstLine}
        <br />
        {location.secondLine}
      </LocationSection>
    ));
  };

  return (
    <InfoSection>
      <Layout>
        <Locations>
          <TitleSection>
            <Title>Visit Us</Title>
          </TitleSection>
          <LocationInfo />
        </Locations>
        <StyledMap src={'https://www.google.com/maps/embed?pb=' + location} />
      </Layout>
    </InfoSection>
  );
};

export default Location;
