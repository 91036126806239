export const LocationData = [
  {
    title: 'Overland Park',
    subtitle: 'Main Office / Sleep Lab',
    firstLine: '11881 W 112th St, Ste 101',
    secondLine: 'Overland Park, KS 66210',
    isSecondaryLocation: false,
    googleMap:
      '!1m18!1m12!1m3!1d3103.939024819351!2d-94.72518928438329!3d38.92537115289925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c0ead2246b5f01%3A0xdbd88c9aa446f73b!2s11881%20W%20112th%20St%20%23101%2C%20Overland%20Park%2C%20KS%2066210!5e0!3m2!1sen!2sus!4v1673667539963!5m2!1sen!2sus',
  },
  {
    title: 'Leavenworth',
    subtitle: 'Sleep Lab',
    firstLine: '3601 S 4th Street, Ste 8',
    secondLine: 'Leavenworth, KS 66048',
    isSecondaryLocation: true,
    googleMap:
      '!1m18!1m12!1m3!1d3088.316091113614!2d-94.90284668437572!3d39.28108153184074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c07cb639d282a5%3A0x192d164a5c5dfca!2sPlaza%20Professional%20Building%2C%203601%20S%204th%20St%2C%20Leavenworth%2C%20KS%2066048!5e0!3m2!1sen!2sus!4v1673675473758!5m2!1sen!2sus',
  },
  {
    title: 'Blue Springs',
    subtitle: 'Sleep Lab',
    firstLine: '104 N Hwy 7, Ste J',
    secondLine: 'Blue Springs, MO 64014',
    isSecondaryLocation: true,
    googleMap:
      '!1m18!1m12!1m3!1d3099.8379671407524!2d-94.27425232349535!3d39.01900957169831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c11a4b80500001%3A0x3f87e5e72718cb15!2s104%20N%2C%20104%20NW%20Hwy%207%20Ste%20J%2C%20Blue%20Springs%2C%20MO%2064014!5e0!3m2!1sen!2sus!4v1684192910860!5m2!1sen!2sus',
  },
];
