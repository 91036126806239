import React, { useEffect } from 'react';
import { ColumnContent, ColumnContentMain, Content, Sleep } from '../Home/Home.elements';
import InfoSection from '../InfoSections';
import TruckerJpg from '../../../resources/trucker.jpg';
import styled from 'styled-components';

const Truckers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <InfoSection>
      <Sleep src={TruckerJpg} alt="Sleep Apnea and Department of Transportation" />
      <Content>
        <ColumnContent>
          <span>
            Sleep Disorders Institute is AASM Accredited Independent diagnostic facility. We understand the importance
            of this to your safety and livelihood. Therefore we want to make the testing and compliance of the drivers
            and the pilots as easy as possible. To make this easy and simple, we:
          </span>
        </ColumnContent>
        <ColumnContentMain>
          <ul style={{ listStyleType: 'none', lineHeight: '1.5' }}>
            <li>- Have Home Sleep Test Units available as early as same day (depending on availability)</li>
            <li>- Have staff verify your insurance benefits and copayments prior to procedure</li>
            <li>- Have payment plans available for the higher deductible and self pay patients</li>
            <li>- Have a lower cost than most hospital like providers - Save $$$</li>
            <li>- Have sleep specialist physicians to evaluate, treat and consult</li>
            <li>- Have sleep equipment and supplies available same day</li>
            <li>- Have the ability to download and provide Compliance Reports</li>
            <li>- Have One on One CPAP sets ups for the best possible mask fitting and CPAP usage training</li>
          </ul>
        </ColumnContentMain>
      </Content>
      <Content>
        <ColumnContentMain>
          <h3>Useful Links:</h3>
          <Links />
        </ColumnContentMain>
      </Content>
    </InfoSection>
  );
};

export default Truckers;

const Links = () => {
  return linkList.map((link) => {
    return <Link href={link.link}>{link.title}</Link>;
  });
};

const Link = styled.a`
  line-height: 1.5;
  color: #39729b;
  font-weight: 600;
`;

const linkList = [
  {
    title: 'National Institute of Health: Definition Sleep Apnea',
    link: 'https://www.nhlbi.nih.gov/health/sleep-apnea',
  },
  {
    title: 'DOT safety Guidelines',
    link: ' https://www.fmcsa.dot.gov/driver-safety/sleep-apnea/driving-when-you-have-sleep-apnea',
  },
  {
    title: 'National Registry of Certified Medical Examiners',
    link: 'https://www.fmcsa.dot.gov/driver-safety/sleep-apnea/driving-when-you-have-sleep-apnea',
  },
  {
    title: 'FMCA Newsroom',
    link: 'https://www.fmcsa.dot.gov/newsroom',
  },
  {
    title: 'FMCSA Resources for Drivers',
    link: 'https://www.fmcsa.dot.gov/resources-for-drivers',
  },
  {
    title: 'Shack attacks  Sleep Apnea',
    link: 'https://www.youtube.com/watch?v=4JkiWvWn2aU',
  },
  {
    title: 'CPAP Treatment',
    link: 'https://www.resmed.com/en-us/sleep-apnea/starting-cpap-therapy/video-story-gallery/',
  },
  {
    title: 'How sleep apnea causes trucking accidents',
    link: 'https://www.freightwaves.com/news/how-sleep-apnea-causes-trucking-accidents',
  },
];
