import React from 'react';
import InfoSection from '../InfoSections';
import { Sleep } from './SleepDisorders.elements';
import SleepingJpg from '../../../resources/hero-area.jpg';
import { Body, ColumnContent, Content, Title } from '../Home/Home.elements';

const SleepDisorders = () => {
  return (
    <InfoSection>
      <Sleep src={SleepingJpg} alt="Sleep Disorders" />
      <Content>
        <ColumnContent style={{ paddingRight: '30px' }}>
          <Title>Understanding Sleep Apnea</Title>
          <Body>
            <TidBit
              title="What Is Sleep Apnea?"
              description=" Sleep apnea is a common but potentially serious sleep disorder
              that occurs when a person's breathing is interrupted during sleep. It is characterized by pauses in
              breathing or shallow breathing, caused by a blockage in the airway. These blockages occur when tissues in
              the airway relax excessively or collapse, cutting off the airway. As you try to take a breath this
              obstruction prevents air from reaching your lungs, causing choking and low oxygen levels. Sleep Apnea
              Sufferers Will Often Sleep Through the Night Unaware of Breathing Pauses, sometimes hundreds of times. This
              means the brain -- and the rest of the body -- may not get enough oxygen. Pauses in breathing (apnea) can
              occur 5 times per hour in mild cases and 50 to 100 times per hour in severe cases. These pauses repeatedly
              interrupt your sleep and respiration, causing poor sleep quality and contributing to daytime sleepiness and
              fatigue. Sleep apnea is not a disease but a disorder that increases the risk of contracting other diseases
              and conditions. "
            />
            <TidBit
              title="What causes sleep apnea?"
              description=" During sleep the muscles at the back of the
              throat relax, in everybody. In some people the muscles relax so much that the wall of the throat collapses.
              As the person tries to breathe, no air can get in. Eventually the effort of breathing wakes him up for a
              short time, and this may happen over and over, more than 100 times per hour, or hundreds of times each
              night. This describes obstructive sleep apnea, or OSA. The persons not aware of waking frequently to
              breathe, but even mild sleep apnea can cause daytime sleepiness, poor memory and concentration, and
              depression. "
            />
            <TidBit
              title="Top 5 Risks of Sleep Apnea"
              description=" Excessive Daytime Sleepiness - Impaired
              Memory and Cognition - Hypertension, Heart Attack and Stroke - Weight Gain - Snoring "
            />
          </Body>
        </ColumnContent>
        <ColumnContent>
          <Title>Other Sleep Disorders</Title>
          <Body>
            <TidBit
              title="Central Sleep Apnea"
              description=" Central sleep apnea is caused when the brain does not send the
              signal to breathe resulting in pauses in breathing and drops in oxygen levels. Central sleep apnea
              requires specialized treatment and the professionals at Sunset Sleep are experts in diagnosing and
              treating all form of apnea. "
            />
            <TidBit
              title="Restless Legs Syndrome (RLS)"
              description=" People who have RLS describe it in many different
              ways, but most describe a “crawling” or “tingling” sensation that occurs in the legs when they are trying
              to sleep. RLS most often occurs in the calves of the legs and most people say it is temporarily relieved
              by stretching or moving the legs. "
            />
            <TidBit
              title="Insomnia"
              description=" Insomnia is characterized by difficulty falling asleep, waking frequently
              during the night, waking too early in the morning and not being able to get back to sleep. Insomnia can be
              transient, intermittent, or chronic. "
            />
            <TidBit
              title="Narcolepsy"
              description=" Narcolepsy is usually described as excessive daytime sleepiness. The
              main symptoms include “sleep attacks” that can occur without warning and cataplexy, which is the sudden
              loss of voluntary muscle control. "
            />
            <TidBit
              title="Periodic Limb Movements (PLMS)"
              description=" Periodic limb movements in sleep are repetitive
              movements, typically in the lower limbs, that occur about every 20-40 seconds. "
            />
          </Body>
        </ColumnContent>
      </Content>
    </InfoSection>
  );
};

export default SleepDisorders;

export const TidBit = ({ title, description }) => {
  return (
    <div style={{ margin: '15px 0' }}>
      <h3>{title}</h3>
      <p style={{ fontWeight: 'lighter', lineHeight: '25px' }}>{description}</p>
    </div>
  );
};
