import React, { useEffect } from 'react';
import InfoSection from '../InfoSections';
import TatinenyJpg from '../../../resources/Tatineny.jpg';
import StevensJpg from '../../../resources/Stevens.jpg';
import { Profile, Doctor, Content, Name, Bio } from './MeetUs.elements';

const MeetUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <InfoSection style={{ display: 'flex', flexDirection: 'row' }}>
      <Content>
        <Doctor>
          <Profile src={StevensJpg} style={{ width: '250px' }} alt="Dr. Suzanne Stevens" />
          <Name>M. Suzanne Stevens MD, MS</Name>
          <Bio>
            Dr. Suzanne Stevens is a sleep neurologist with over 20 years of experience treating sleep disorders.
            Medical school was completed here in Kansas City at the University of Missouri at Kansas City. Internship
            was done at Washington University in St. Louis. Neurology residency and sleep fellowship were done at Rush
            University in Chicago, IL. She was on staff at Rush for 2 years, then moved to Kansas City and has been here
            since 2003.
            <br />
            <br />
            She started the neurology sleep lab at KU and co-directed and started the sleep training fellowship at KU.
            She has been involved in sleep research. Her passion is seeing patients and educating patients and other
            physicians about sleep disorders. She has training to treat all sleep disorders, but has special expertise
            in hypersomnias (idiopathic hypersomnia, narcolepsy), refractory restless legs syndrome, parasomnias and
            alternative treatments for OSA (such as Inspire). Additionally, Dr. Stevens is board certified in both
            neurology and sleep medicine.
          </Bio>
        </Doctor>
        <Doctor>
          <Profile src={TatinenyJpg} alt="Dr. Suzanne Stevens" />
          <Name>Prathusha Tatineny Dasari MD, MPH</Name>
          <Bio>
            Dr. Tatineny was born and raised in the Midwest. She has worked in different parts of the US. She completed
            her sleep medicine fellowship at the University of Missouri-Kansas City. Her primary board certification is
            in Family Medicine. She is ABFM certified. She is a member of the American Academy of Family Physicians
            (AAFP) and is also a member of the American Academy of Sleep Medicine (AASM). She has published research in
            various topics. In addition to her medical degree, she has a Master’s in Public Health.
            <br />
            <br />
            Dr. Tatineny is passionate about treating sleep disorders and improving her patients’ quality of life. In
            her spare time, she enjoys spending time with her husband, family and friends. She also enjoys traveling,
            dancing and yoga. She has a black belt in karate, played Varsity Tennis and completed her Indian classical
            dance graduation in an art form called Kuchipudi. She has performed in multiple cultural events.
          </Bio>
        </Doctor>
      </Content>
    </InfoSection>
  );
};

export default MeetUs;
